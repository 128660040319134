import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  scheduleTitle: {
    id: "movie-page.schedule-title",
    defaultMessage: "{movieTitle} showtimes...",
    description: `Schedule title in a movie page`,
  },
  selectedTheaterPlaceholder: {
    id: "schedule.no-theater-placeholder-message",
    defaultMessage: "Select a theater to see showtimes",
  },
});

export default messages;
