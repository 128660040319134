/** @jsx jsx */
import { MovieDetails, responsive } from "@boxoffice/screenplay";
import { Gallery } from "gatsby-theme-gallery";
import { MoviePageHeaderProps } from "gatsby-theme-movie-pages";
import messages from "gatsby-theme-movie-pages/src/templates/MoviePage/Content/Header/TechDetails/i18n";
import React, { memo } from "react";
import MoviePoster from "shared/components/MoviePoster";
import PlayButton from "shared/components/PlayButton";
import { formatDuration } from "shared/helpers/duration";
import useIntl from "shared/helpers/i18n/useIntl";
import { createModalPortal } from "shared/helpers/portal";
import { formatRelease } from "shared/helpers/release";
import useAnalytics from "shared/hooks/useAnalytics";
import useMovieTrailer from "shared/hooks/useMovieTrailer";
import usePageContext from "shared/hooks/usePageContext";
import durationMessages from "shared/i18n/duration.i18n";
import { Container, Grid, jsx, Themed } from "theme-ui";

import Schedule from "../Schedule";

const MoviePageHeader: React.FC<MoviePageHeaderProps> = ({
  movie,
  selectedTheater,
  movieSchedule,
}) => {
  const { formatMessage } = useIntl();
  const durationFormat = formatMessage(durationMessages.runtime);
  const analytics = useAnalytics();
  const [MovieTrailer, launchMovieTrailer, hasTrailer] = useMovieTrailer(movie);
  const { intl } = usePageContext();

  return (
    <Container>
      <div
        sx={{
          // @ts-expect-error no type for sectionVerticalGutter
          paddingTop: (theme) => theme.sizes?.sectionVerticalGutter,
          paddingBottom: responsive({ xs: 5, lg: 6 }),
        }}
      >
        <Grid
          gap={4}
          columns={responsive({
            xs: "1fr 3fr",
            md: "1fr 3fr",
            lg: "1fr 3fr 1fr",
          })}
          sx={{
            gridTemplateAreas: responsive({
              xs: '"poster details" "synopsis synopsis" "schedule schedule" "tech tech"',
              md: '"poster details" "poster synopsis" "poster schedule" "tech tech"',
              lg: '"poster details tech" "poster synopsis tech" "poster schedule tech"',
            }),
          }}
        >
          <div sx={{ gridArea: "poster" }}>
            <div sx={{ position: "relative" }}>
              <MoviePoster
                poster={movie.poster}
                title={movie.title}
                sizes={responsive({
                  xs: 576,
                  sm: 136,
                  md: 168,
                  lg: 232,
                  xl: 296,
                  xxl: 360,
                })}
              />
              {hasTrailer && (
                <div
                  onClick={
                    hasTrailer
                      ? (event) => {
                          event.preventDefault();
                          event.stopPropagation();

                          launchMovieTrailer();
                          analytics.track("click", {
                            category: "Video",
                            label: "trailer",
                            movieId: movie.id,
                            movieTitle: movie.title || undefined,
                          });
                        }
                      : undefined
                  }
                  sx={{
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                  }}
                >
                  <PlayButton />
                </div>
              )}
            </div>
          </div>
          <div sx={{ gridArea: "details" }}>
            <MovieDetails
              title={<Themed.h1>{movie.title || "—"}</Themed.h1>}
              release={formatRelease(movie?.release, intl.locale.long) || ""}
              certificate={movie.certificate || ""}
              runtime={
                movie.runtime
                  ? formatDuration(movie.runtime, durationFormat)
                  : "—"
              }
            />
          </div>
          <div sx={{ gridArea: "synopsis" }}>
            {movie.synopsis && <MovieDetails synopsis={movie.synopsis} />}
          </div>
          <div sx={{ gridArea: "schedule" }}>
            <Schedule
              movie={movie}
              selectedTheater={selectedTheater}
              movieSchedule={movieSchedule || null}
            />
          </div>
          <div
            sx={{
              gridArea: "tech",
              paddingTop: responsive({ lg: "6.25rem" }),
            }}
          >
            <div sx={{ marginBottom: 4 }}>
              <ul sx={{ listStyle: "none", margin: 0, padding: 0 }}>
                <li>
                  <strong sx={{ marginRight: 1 }}>
                    {formatMessage(messages.genre)}
                  </strong>
                  {movie.genres}
                </li>
                <li>
                  <strong sx={{ marginRight: 1 }}>
                    {formatMessage(messages.direction)}
                  </strong>
                  {movie.direction}
                </li>
                <li>
                  <strong sx={{ marginRight: 1 }}>
                    {formatMessage(messages.casting)}
                  </strong>
                  {movie.casting}
                </li>
              </ul>
            </div>
            <Gallery
              aspectRatio={16 / 9}
              displayImageColumns={2}
              displayOptionsZoomable
              displayImageMode={"GRID"}
              pictures={movie.pictures
                ?.slice(0, 6)
                ?.filter((picture): picture is string => !!picture)
                ?.map((picture) => ({
                  alt: movie.title || "",
                  src: picture,
                }))}
            />
          </div>
        </Grid>
      </div>
      {createModalPortal(MovieTrailer)}
    </Container>
  );
};

export default memo(MoviePageHeader);
