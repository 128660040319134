/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import { MoviePageScheduleProps } from "gatsby-theme-movie-pages";
import messages from "gatsby-theme-movie-pages/src/templates/MoviePage/i18n";
import { ClosureMessage } from "gatsby-theme-warning-message";
import React, { memo } from "react";
import ThemeProvider from "shared/components/ThemeProvider";
import { getTinyColor } from "shared/helpers/colors";
import useIntl from "shared/helpers/i18n/useIntl";
import { loadable } from "shared/helpers/loadableComponent";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { jsx } from "theme-ui";

const Schedule = loadable(() => import("gatsby-theme-showtimes-grid"), {
  resolveComponent: (components) => components.Schedule,
});

const ScheduleOptionsBar = loadable(
  () => import("gatsby-theme-schedule-options-bar"),
  {
    resolveComponent: (components) => components.ScheduleOptionsBar,
  }
);

const MoviePageSchedule: React.FC<MoviePageScheduleProps> = ({
  movie,
  movieSchedule,
  selectedTheater,
}) => {
  const { formatMessage } = useIntl();
  const pageContext = usePageContext();
  const [selectedTheaterId] = useSelectedTheaterId();

  return (
    <ThemeProvider
      colors={(rawColors) => ({
        background: getTinyColor(rawColors?.muted)
          .mix(
            typeof rawColors?.background === "string"
              ? rawColors.background
              : "#000",
            80
          )
          .toHexString(),
      })}
    >
      <div
        id={"showtimes"}
        sx={{
          padding: responsive({ xs: 2, sm: 4 }),
          backgroundColor: "background",
          color: "text",
          borderRadius: "medium",
        }}
      >
        <div sx={{ marginBottom: 4 }}>
          <ScheduleOptionsBar
            view={"NOW_PLAYING"}
            movieIds={[movie.id]}
            includeTheaterSelector={!pageContext.isSingleLocation}
            includeDateSlider
            includeDatePicker
            theaterId={selectedTheaterId}
            dateSliderSlidesPerViewScale={{
              xs: 2,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 5,
              xxl: 5,
            }}
            fullWidth
          />
        </div>

        {!pageContext.isSingleLocation && (
          <ClosureMessage theaterId={selectedTheater?.id} />
        )}

        {!selectedTheater && (
          <div sx={{ fontWeight: "bold", fontSize: 3 }}>
            {formatMessage(messages.selectedTheaterPlaceholder)}
          </div>
        )}

        {selectedTheater && (
          <Schedule
            movie={movie}
            theater={selectedTheater}
            schedule={movieSchedule || null}
            groupType={"SHARED"}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default memo(MoviePageSchedule);
