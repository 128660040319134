import { graphql } from "gatsby";
import { ADS } from "gatsby-theme-ads";
import { SEO } from "gatsby-theme-seo";
import React, { memo } from "react";
import { loadable } from "shared/helpers/loadableComponent";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";

import type { MoviePageQuery } from "../../../__generated__/gatsby.types";
import MoviePageComponent from "./Component";

const ScheduleContextProvider = loadable(
  () => import("gatsby-theme-schedule-options-bar"),
  {
    resolveComponent: (components) => components.ScheduleContextProvider,
  }
);

export type Props = {
  data: MoviePageQuery;
};

const MoviePage: React.FC<Props> = ({ data }) => {
  const pageContext = usePageContext();
  const [selectedTheaterId] = useSelectedTheaterId();

  const movieData = data.movie;

  if (!movieData) {
    return null;
  }

  const selectedTheater = pageContext.isSingleLocation
    ? data.allTheater?.nodes?.[0]
    : data.allTheater?.nodes.find(
        (theater) => theater.id === selectedTheaterId
      );

  const title = data.movie?.title || "";

  return (
    <ScheduleContextProvider
      showtimeWeekReleaseDay={selectedTheater?.showtimeWeekReleaseDay}
      timeZone={selectedTheater?.timeZone}
      defaultRange={"TODAY"}
    >
      <SEO title={title} />
      <ADS pageTitle={title} />
      <MoviePageComponent data={data} selectedTheater={selectedTheater} />
    </ScheduleContextProvider>
  );
};

export const query = graphql`
  query MoviePage($movieId: String) {
    allTheater(sort: { fields: name, order: ASC }) {
      nodes {
        id
        name
        timeZone
        showtimeWeekReleaseDay
      }
    }
    movie(id: { eq: $movieId }) {
      id
      path
      title
      certificate
      runtime
      synopsis
      genres
      direction
      casting
      poster
      trailer {
        SD
        HD
      }
      pictures
      release
    }
  }
`;

export default memo(MoviePage);
