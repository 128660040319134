import { DateTime } from "luxon";

import { FALLBACK_LOCALE } from "../constants";

export const formatRelease = (
  releaseDate?: string | null,
  locale?: string
): string | undefined => {
  if (!releaseDate) {
    return;
  }

  return DateTime.fromISO(releaseDate, { zone: "UTC" })
    .setLocale(locale || FALLBACK_LOCALE)
    .toLocaleString(DateTime.DATE_SHORT);
};
