/** @jsx jsx */
import { FeaturedEvents } from "gatsby-theme-event-pages";
import { Footer } from "gatsby-theme-footer";
import { Header } from "gatsby-theme-header";
// eslint-disable-next-line import/no-extraneous-dependencies
import WidgetWrapper from "gatsby-theme-pages/src/components/WidgetWrapper";
import { useScheduleContext } from "gatsby-theme-schedule-options-bar";
import { ClosureMessage, EmergencyMessage } from "gatsby-theme-warning-message";
import React, { memo } from "react";
import useAllocineSchedule from "shared/hooks/useAllocineSchedule";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import type { MoviePageQuery } from "../../../__generated__/gatsby.types";
import Content from "./Content";
import sxStyles from "./styles";
import type { Theater } from "./types";

export type Props = {
  data: MoviePageQuery;
  selectedTheater?: Theater;
};

const MoviePageComponent: React.FC<Props> = ({ data, selectedTheater }) => {
  const pageContext = usePageContext();
  const { rangeSelection } = useScheduleContext();

  const { schedule } = useAllocineSchedule(
    selectedTheater?.id,
    selectedTheater?.timeZone || undefined,
    data.movie?.id ? [data.movie.id] : [],
    rangeSelection.range[0],
    rangeSelection.range[1]
  );

  const movie = data.movie;
  if (!movie) {
    return null;
  }

  const movieSchedule = schedule[movie.id] || undefined;

  return (
    <div sx={sxStyles.pageWrapper}>
      <Header />

      <div sx={sxStyles.pageContainer}>
        <EmergencyMessage />
        {pageContext.isSingleLocation && (
          <ClosureMessage isSingleLocation withContainer />
        )}
        <Content
          movie={movie}
          movieSchedule={movieSchedule}
          selectedTheater={selectedTheater}
        />
      </div>
      <WidgetWrapper
        isFirst={false}
        isLast={true}
        isNested={false}
        containerSize={"FULL"}
        widget={{
          __typename: "WidgetEvents",
          id: "0",
          eventsShape: { display: "FEATURED" },
        }}
        prevWidget={null}
        nextWidget={null}
        contained={false}
      >
        <FeaturedEvents
          theaterId={selectedTheater?.id}
          isNested={false}
          containerSize={"FULL"}
        />
      </WidgetWrapper>
      <Footer />
    </div>
  );
};

export default memo(MoviePageComponent);
