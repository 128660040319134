import { responsive } from "@boxoffice/screenplay";
import { ThemeUIStyleObject } from "theme-ui";

const techDetailWrapper: ThemeUIStyleObject = {
  display: "block",
  gridTemplateColumns: "1fr 1fr",
  columnGap: 3,
  marginBottom: 3,
  gridTemplateRows: "auto",
};

const techDetailWrapperWithPictures: ThemeUIStyleObject = {
  display: "grid",
  gridTemplateAreas: '"tech tech" "pictures pictures"',
};

const techDetailWrapperWithTrailers: ThemeUIStyleObject = {
  display: "grid",
  gridTemplateAreas: responsive({
    xs: '"trailer trailer" "tech tech" "pictures pictures"',
    lg: '"trailer tech" "pictures pictures"',
  }),
};

const sxStyles: Record<
  | "pageWrapper"
  | "pageContainer"
  | "headerWrapper"
  | "headerContainer"
  | "headerDetails"
  | "headerTechDetails"
  | "detailsWrapper"
  | "detailsArea"
  | "detailsPosterArea"
  | "detailsContentShortcut"
  | "detailsContentShortcutLink"
  | "techDetailWrapper"
  | "techDetailWrapperWithPictures"
  | "techDetailWrapperWithTrailers"
  | "techDetailsList"
  | "techDetailLabel"
  | "techDetailTrailerArea"
  | "techDetailTrailerPlaceholder"
  | "techArea"
  | "picturesArea"
  | "scheduleWrapper"
  | "scheduleTitleWrapper"
  | "scheduleOptionBar"
  | "schedulePlaceholder",
  ThemeUIStyleObject
> = {
  pageWrapper: {
    variant: "siteWrapper",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  },
  pageContainer: { flex: 1 },
  headerWrapper: { variant: "boxes.hero", width: "100vw" },
  headerContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridTemplateRows: "auto",
    gridTemplateAreas: responsive({
      xs: '"details details details details" "assets assets assets assets"',
      md: '"details details assets assets"',
    }),
    columnGap: 4,
    paddingY: 4,
  },
  headerDetails: {
    gridArea: "details",
    marginBottom: responsive({ xs: 3, md: 0 }),
  },
  headerTechDetails: {
    gridArea: "assets",
    paddingTop: responsive({ xs: 0, lg: 4 }),
  },
  detailsWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "auto",
    gridTemplateAreas: responsive({
      xs: '"details details" "poster poster" "shortcut shortcut"',
      sm: '"details poster" "shortcut shortcut"',
      md: '"poster poster" "details details" "shortcut shortcut"',
      lg: '"details poster" "shortcut shortcut"',
    }),
    columnGap: responsive({ xs: 0, sm: 3, lg: 4 }),
  },
  detailsArea: {
    gridArea: "details",
    marginBottom: responsive({ xs: 3, md: 0 }),
  },
  detailsPosterArea: {
    gridArea: "poster",
    borderRadius: "medium",
    overflow: "hidden",
    marginBottom: responsive({ xs: 0, md: 3 }),
  },
  detailsContentShortcut: {
    gridArea: "shortcut",
    display: responsive({ xs: "block", md: "none" }),
    marginTop: 3,
  },
  detailsContentShortcutLink: {
    variant: "buttons.showtime",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  techDetailWrapper,
  techDetailWrapperWithPictures,
  techDetailWrapperWithTrailers,
  techDetailsList: { listStyle: "none", margin: 0, padding: 0 },
  techDetailLabel: { marginRight: 1 },
  techDetailTrailerArea: {
    gridArea: "trailer",
    marginBottom: responsive({ xs: 3, sm: 4, md: 3, lg: 0 }),
  },
  techDetailTrailerPlaceholder: {
    backgroundColor: "rgba(0,0,0,.25)",
    overflow: "hidden",
    borderRadius: "medium",
  },
  techArea: { gridArea: "tech" },
  picturesArea: { gridArea: "pictures" },
  scheduleWrapper: { paddingY: 4 },
  scheduleTitleWrapper: { textAlign: "center", marginBottom: 4 },
  scheduleOptionBar: { marginBottom: 4 },
  schedulePlaceholder: { fontWeight: "bold", fontSize: 3 },
};

export default sxStyles;
