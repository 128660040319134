import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  genre: {
    id: "movie-page.movie-tech-details.genre-label",
    defaultMessage: "Genre:",
  },
  direction: {
    id: "movie-page.movie-tech-details.direction-label",
    defaultMessage: "Direction:",
  },
  casting: {
    id: "movie-page.movie-tech-details.casting-label",
    defaultMessage: "Casting:",
  },
});

export default messages;
